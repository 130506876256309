import { Brick, BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'creative_all',
    title: 'Creative',
    channel: 'assets',
    description: 'An asset based on a template',
    addBrickText: 'Add creative brick',
    availableParentBricks: [],
    availableChildBricks: [],
    outputAction: 'all',
    defaultTab: 'settings',
    dynamicTab: (brick: Brick) => {
        if (brick?.data?.creatives?.length) {
            return 'creatives';
        }
        return undefined;
    },
    config: {
        general: {
            creativeSelector: {
                multiple: false,
                canEditCreative: false,
                isButton: true,
                selectors: ['template'],
                templateTypes: [
                    'displayAdDesigned',
                    'dynamicImageDesigned',
                    'dynamicVideoDesigned',
                    'displayAd',
                    'dynamicImage',
                    'dynamicVideo',
                    'dynamicPDF',
                    'dynamicPDFDesigned',
                    'dynamicAfterEffects',
                    'dynamicInDesign'
                ]
            }
        }
    },
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'creatives',
            title: 'Creative',
            type: 'component',
            component: 'creatives'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Export',
            type: 'component',
            component: 'output'
        }
    ]
};

export default setup;
