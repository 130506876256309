import React from 'react';
import { Brick } from 'components/bricks/types/brick.type';
import AssetWarningMessage from 'components/bricks/components/shared/components/asset-warning-message';

interface Props {
    brick: Brick;
}

const widget = ({ brick }: Props) => {
    return <AssetWarningMessage brick={brick} />;
};

export default widget;
