import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'creative_format',
    title: 'Creative Format',
    channel: undefined,
    description: '',
    addBrickText: '',
    availableParentBricks: [],
    availableChildBricks: [],
    outputAction: 'download',
    tabs: [],
    isCustomBrick: true,
    config: {
        general: {
            publish: {
                previewType: 'creatives'
            }
        }
    }
};

export default setup;
